import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppState} from '../../../../services/app-state';
import {select, Store} from '@ngrx/store';
import {totalProductsAmount} from '../../../cart/reducers/cart.reducer';
import {selectIsLoggingIn} from '../../../auth/reducers/auth.reducer';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent implements OnInit {
  @Input() showBackButton = true;
  @Input() defaultBackUrl = '/tabs/home';
  @Input() title: string = null;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {}

  get defaultHref$() {
    return this.store.pipe(
      select(selectIsLoggingIn),
      map((loggedIn) => loggedIn ? this.defaultBackUrl : '/login')
      );
  }

  get cartTotals$()  {
    return this.store.pipe(select(totalProductsAmount));
  }

  get isLoggedIn$()  {
    return this.store.pipe(select(selectIsLoggingIn));
  }
}
