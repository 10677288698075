import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavController} from '@ionic/angular';
import {map, take} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {isLoggedIn} from '../reducers/auth.reducer';
import {AppState} from '../../../services/app-state';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {

  constructor(private store: Store<AppState>, private navCtrl: NavController) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(isLoggedIn),
      take(1),
      map(e => {
        console.log(e);
        if (!e) {
          return true;
        }
        this.navCtrl.navigateRoot('/tabs/home');
        return false;
      })
    );
  }
}
