import {ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {MouseEvent} from '@agm/core/map-types';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {google} from '@agm/core/services/google-maps-types';
import {MapsAPILoader} from '@agm/core';
import {AddressService} from '../../services/address.service';

@Component({
  selector: 'app-find-address-modal',
  templateUrl: './find-address-modal.component.html',
  styleUrls: ['./find-address-modal.component.scss'],
})
export class FindAddressModalComponent implements OnInit {
  @ViewChild('searchbar', {static: false, read: ElementRef}) public searchbarElement: ElementRef;
  @Input() coords;
  public map = {
    lat: 52.0699943,
    long: 5.402666
  };
  public marker = {
    lat: null,
    long: null
  };
  deliveryForm: FormGroup;
  zoom = 5;

  constructor(
    private modalCtrl: ModalController,
    private mapsAPILoader: MapsAPILoader,
    private ref: ChangeDetectorRef,
    private addressService: AddressService,
    private ngZone: NgZone,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.mapsAPILoader.load().then(() => {
      setTimeout(() => {
        const searchInput = this.searchbarElement.nativeElement.querySelector('.searchbar-input');

        const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInput, {
          // types: ['address']
        });
        autocomplete.setComponentRestrictions({country: ['nl']});

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            this.map.lat = autocomplete.getPlace().geometry.location.lat();
            this.map.long = autocomplete.getPlace().geometry.location.lng();
            this.zoom = 17;
            this.ref.detectChanges();
          });
        });
      }, 100);
    });

    this.deliveryForm = this.formBuilder.group({
      delivery_street_address: ['', Validators.required],
      delivery_housenumber: ['', Validators.required],
      delivery_housenumber_add: [''],
      delivery_postcode: ['', [Validators.required, Validators.pattern(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)]],
      delivery_city: ['', Validators.required],
    });

    if (!this.coords) {
      this.alertController.create({
        message: `
                    <strong>Uw locatie kan momenteel niet bepaald worden. U kunt zelf uw locatie bepalen door een marker op de kaart te plaatsen.</strong>
                  `,
        buttons: ['Doorgaan']
      }).then((alert) => {
        alert.present();

        this.ref.detectChanges();
      });

      return;
    }

    this.zoom = 17;

    this.map.lat = this.coords.latitude;
    this.map.long = this.coords.longitude;
    this.marker.lat = this.coords.latitude;
    this.marker.long = this.coords.longitude;

    this.deliveryForm.patchValue({
      delivery_street_address: this.coords.street,
      delivery_housenumber: this.coords.house_number,
      delivery_housenumber_add: this.coords.house_number_addition,
      delivery_postcode: this.coords.zipcode,
      delivery_city: this.coords.city,
    });


    this.alertController.create({
      message: `
                  <strong>Op basis van GPS hebben wij dit adres gevonden:</strong><br /><br />
                  ${this.coords.street} ${this.coords.house_number}${this.coords.house_number_addition}<br />
                  ${this.coords.zipcode}<br />
                  ${this.coords.city}<br /><br />
                  <strong>U kunt dit adres gebruiken of een ander adres zoeken op de kaart</strong>
                `,
      buttons: ['Doorgaan']
    }).then((alert) => {
      alert.present();
      this.ref.detectChanges();
    });
  }

  setMarker(coords) {
    console.log(coords);
    this.marker.lat = coords.latitude;
    this.marker.long = coords.longitude;
    this.deliveryForm.patchValue({
      delivery_street_address: coords.street,
      delivery_housenumber: coords.house_number,
      delivery_housenumber_add: coords.house_number_addition,
      delivery_postcode: coords.zipcode,
      delivery_city: coords.city,
    });
    this.ref.detectChanges();
  }

  confirmAddress() {
    this.modalCtrl.dismiss({deliveryForm: this.deliveryForm.value, coords: this.marker});
  }

  lookupPlace($event: MouseEvent) {
    this.loadingController.create({message: 'Het adres van het door u aangeklikte punt wordt nu opgezocht. Een moment geduld a.u.b.'}).then((loading) => {
      loading.present();
      this.addressService.getAddressByCords($event.coords.lat, $event.coords.lng).subscribe((data) => {
        loading.dismiss();
        this.alertController.create({
          message: `
                  <strong>Op deze plek hebben wij hetvolgende adres gevonden:</strong><br /><br />
                  ${data.street} ${data.house_number}${data.house_number_addition}<br />
                  ${data.zipcode}<br />
                  ${data.city}<br /><br />
                  <strong>Wilt u dit adres gebruiken als afleveradres?</strong>
                `,
          buttons: [{
            text: 'Nee',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Ja',
            cssClass: 'primary',
            handler: () => {
              this.setMarker(data);
            }
          }]
        }).then((alert) => alert.present());
      });
    });
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
