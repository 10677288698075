import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LoggedInGuard} from './modules/auth/guards/logged-in.guard';
import {NotLoggedInGuard} from './modules/auth/guards/not-logged-in.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./modules/auth/pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./modules/auth/pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'tabs',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'products',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/products-page/products-page.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'cart-placement-information',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/cart/pages/cart-placement-information/cart-placement-information.module')
      .then( m => m.CartPlacementInformationPageModule)
  },
  {
    path: 'payment-method',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/cart/pages/payment-method/payment-method.module')
      .then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'user-details',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/cart/pages/user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/auth/pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'order-response/:orders_id',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/cart/pages/order-response/order-response.module').then( m => m.OrderResponsePageModule)
  },
  {
    path: 'generic-message-page',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/messages/pages/generic-message-page/generic-message-page.module')
      .then( m => m.GenericMessagePagePageModule)
  },
  {
    path: 'register-my-account',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./modules/auth/pages/register-my-account/register-my-account.module')
      .then( m => m.RegisterMyAccountPageModule)
  },
  {
    path: '**',
    canActivate: [LoggedInGuard],
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
