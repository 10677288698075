import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CartStoreProduct} from '../../models/cart-store-product.model';
import {AppState} from '../../../../services/app-state';
import {select, Store} from '@ngrx/store';
import {cartTotals, productsInCart} from '../../reducers/cart.reducer';

@Component({
  selector: 'app-cart-totals',
  templateUrl: './cart-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cart-totals.component.scss'],
})
export class CartTotalsComponent implements OnInit {
  @Input() cartStoreProduct: CartStoreProduct[];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
  }

  get cartTotals$() {
    return this.store.pipe(select(cartTotals));
  }
}
