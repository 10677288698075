import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ActionReducer, ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {AuthReducer} from './modules/auth/reducers/auth.reducer';
import {AuthUserReducer} from './modules/auth/reducers/auth-user.reducer';
import {AuthModule} from './modules/auth/auth.module';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './modules/auth/effects/auth.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CartReducer} from './modules/cart/reducers/cart.reducer';
import {HeaderModule} from './modules/header/header.module';
import {CartModule} from './modules/cart/cart.module';
import {CategoriesModule} from './modules/categories/categories.module';
import {ProductSelectorModalPageModule} from './modules/categories/components/product-selector-modal/product-selector-modal.module';
import {ProductsModule} from './modules/products/products.module';
import {HttpClient} from './modules/http/services/http-client.service';
import {HttpAuthIntercepter} from './modules/http/services/http-auth.intercepter';
import {AppState} from './services/app-state';
import {localStorageSync} from 'ngrx-store-localstorage';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeNL from '@angular/common/locales/nl';
import {registerLocaleData} from '@angular/common';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {AgmCoreModule} from '@agm/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

registerLocaleData(localeNL, 'nl-NL');

const reducers: ActionReducerMap<AppState> = {
  auth: AuthReducer,
  authUser: AuthUserReducer,
  cart: CartReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['auth', 'authUser', 'cart'], rehydrate: true})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: environment.googleMapsKey,
      libraries: ['places']
    }),
    ProductsModule,
    CategoriesModule,
    HeaderModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    AuthModule,
    AppRoutingModule,
    EffectsModule.forRoot([AuthEffects]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    CartModule,
    ProductSelectorModalPageModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    InAppBrowser,
    HttpClient,
    SplashScreen,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthIntercepter,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
