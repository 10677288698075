import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultHeaderComponent} from './components/default-header/default-header.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    DefaultHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule
  ],
  exports: [DefaultHeaderComponent]
})
export class HeaderModule {
}
