export function calculateMapInfo(coords: {lat: number, long: number}[]): {zoom: number, lat: number, long: number} {
  const topLeft = {
    lat: null,
    long: null
  };
  const bottomRight = {
    lat: null,
    long: null
  };

  coords.forEach((coord) => {
    if (!topLeft.lat || topLeft.lat > coord.lat) {
      topLeft.lat = coord.lat;
    }
    if (!topLeft.long || topLeft.long > coord.long) {
      topLeft.long = coord.long;
    }
    if (!bottomRight.lat || bottomRight.lat < coord.lat) {
      bottomRight.lat = coord.lat;
    }
    if (!bottomRight.long || bottomRight.long < coord.long) {
      bottomRight.long = coord.long;
    }
  });

  const lat: number = topLeft.lat + (bottomRight.lat - topLeft.lat) / 2;
  const long: number = topLeft.long + (bottomRight.long - topLeft.long) / 2;
  const diffA = bottomRight.lat - topLeft.lat;
  const diffB = bottomRight.lat - topLeft.lat;


  const maxDiff = diffA > diffB ? diffA : diffB;

  let zoom = 14;
  if (maxDiff <= 0.0007) {
    zoom = 18;
  } else if (maxDiff <= 0.0013) {
    zoom = 17;
  } else if (maxDiff <= 0.003) {
    zoom = 16;
  } else if (maxDiff <= 0.004) {
    zoom = 15;
  }

  return {
    zoom,
    lat,
    long
  };
}
