import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CartTotalsComponent} from './components/cart-totals/cart-totals.component';
import {IonicModule} from '@ionic/angular';
import {SharedModule} from '../common/shared.module';
import {AgmCoreModule} from '@agm/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FindAddressModalComponent} from '../address/components/find-address-modal/find-address-modal.component';



@NgModule({
  declarations: [
    CartTotalsComponent,
    FindAddressModalComponent
  ],
  exports: [
    CartTotalsComponent,
    FindAddressModalComponent
  ],
  entryComponents: [
    FindAddressModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    AgmCoreModule,
    ReactiveFormsModule
  ]
})
export class CartModule { }
