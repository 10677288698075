import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {filter, map, shareReplay} from 'rxjs/operators';
import {ProductCategory} from '../models/product-category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private productCategories$: Observable<ProductCategory[]>;

  constructor(private http: HttpClient) { }


  public index(): Observable<ProductCategory[]> {
    if (!this.productCategories$) {
      this.productCategories$ = this.http
        .get<ProductCategory[]>(`${environment.apiHost}product-categories`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.productCategories$;
  }

  public get(categoryId: number): Observable<ProductCategory> {
    return this.http.get<ProductCategory>(`${environment.apiHost}product-categories/${categoryId}`);
  }
}
