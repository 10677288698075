import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ProductListComponent} from './components/product-list/product-list.component';

@NgModule({
  declarations: [ProductListComponent],
  exports: [ProductListComponent],
  entryComponents: [ProductListComponent],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class ProductsModule { }
