import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoriesComponent} from './components/categories/categories.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../common/shared.module';


@NgModule({
  declarations: [CategoriesComponent],
  exports: [
    CategoriesComponent
  ],
  entryComponents: [

  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SharedModule
  ]
})
export class CategoriesModule { }
