import {CartOrderDetails} from '../models/cart-order-details.model';
import {CartStore} from '../models/cart-store.model';
import {AuthUserStore} from '../../auth/models/auth-user-store.model';

export function dataToCartOrderDetails(data: any): CartOrderDetails {
  return {
    delivery_firstname: data.delivery_firstname ? data.delivery_firstname : '',
    delivery_lastname: data.delivery_lastname ? data.delivery_lastname : '',
    delivery_middlename: data.delivery_middlename ? data.delivery_middlename : '',
    delivery_street_address: data.delivery_street_address ? data.delivery_street_address : '',
    delivery_housenumber: data.delivery_housenumber ? data.delivery_housenumber : '',
    delivery_housenumber_add: data.delivery_housenumber_add ? data.delivery_housenumber_add : '',
    delivery_city: data.delivery_city ? data.delivery_city : '',
    delivery_postcode: data.delivery_postcode ? data.delivery_postcode : '',
    delivery_country: data.delivery_country ? data.delivery_country : '',
    delivery_company: data.delivery_company ? data.delivery_company : '',

    billing_firstname: data.billing_firstname ? data.billing_firstname : '',
    billing_lastname: data.billing_lastname ? data.billing_lastname : '',
    billing_middlename: data.billing_middlename ? data.billing_middlename : '',
    billing_street_address: data.billing_street_address ? data.billing_street_address : '',
    billing_housenumber: data.billing_housenumber ? data.billing_housenumber : '',
    billing_housenumber_add: data.billing_housenumber_add ? data.billing_housenumber_add : '',
    billing_city: data.billing_city ? data.billing_city : '',
    billing_postcode: data.billing_postcode ? data.billing_postcode : '',
    billing_country: data.billing_country ? data.billing_country : '',
    billing_company: data.billing_company ? data.billing_company : '',

    customers_telephone: data.customers_telephone ? data.customers_telephone : '',
  };
}

export function deliveryDataToBillingData(data: any) {
  if (!data) {
    return data;
  }
  return {
    billing_firstname: data.customers_firstname ? data.customers_firstname : '',
    billing_lastname: data.customers_lastname ? data.customers_lastname : '',
    billing_middlename: data.customers_middlename ? data.customers_middlename : '',
    billing_street_address: data.customers_street_address ? data.customers_street_address : '',
    billing_housenumber: data.customers_housenumber ? data.customers_housenumber : '',
    billing_housenumber_add: data.customers_housenumber_add ? data.customers_housenumber_add : '',
    billing_city: data.customers_city ? data.customers_city : '',
    billing_postcode: data.customers_postcode ? data.customers_postcode : '',
    billing_country: data.customers_country ? data.customers_country : '',
    billing_company: data.customers_company ? data.customers_company : '',
    customers_telephone: data.customers_telephone ? data.customers_telephone : '',
  };
}

export function cartStoreToOrderData(cartStore: CartStore, authUserStore: AuthUserStore, mergeData: any = {}) {
  const orderData: any = {...cartStore.order_details};
  orderData.orders_date_delivery = cartStore.orders_date_delivery;
  orderData.preferred_timeslot = cartStore.preferred_timeslot;
  orderData.orders_customer_notice = cartStore.orders_customer_notice;
  orderData.order_products = Object.keys(cartStore.order_products).map((key) => {
    return {
      products_id: cartStore.order_products[key].product.products_id,
      quantity: cartStore.order_products[key].amount
    };
  });
  orderData.pickup_order_products = Object.keys(cartStore.pickup_order_products).map((key) => {
    return {
      order_products_id: cartStore.pickup_order_products[key].orderProduct.orders_products_id
    };
  });
  return {
    ...orderData,
    delivery_firstname: authUserStore.first_name,
    delivery_lastname: authUserStore.last_name,
    customers_firstname: authUserStore.first_name,
    customers_lastname: authUserStore.last_name,
    customers_middlename: '',
    customers_street_address: authUserStore.customer.customers_streetname,
    customers_housenumber: authUserStore.customer.customers_housenumber,
    customers_housenumber_add: authUserStore.customer.customers_housenumber_add,
    customers_city: authUserStore.customer.customers_city,
    customers_postcode: authUserStore.customer.customers_zipcode,
    customers_country: 'Nederland',
    customers_company: authUserStore.customer.customers_company_name,
    customers_telephone: authUserStore.customer.customers_telephone,
    customers_email_address: authUserStore.email,
    billing_firstname: authUserStore.first_name,
    billing_lastname: authUserStore.last_name,
    billing_middlename: '',
    billing_street_address: authUserStore.customer.customers_streetname,
    billing_housenumber: authUserStore.customer.customers_housenumber,
    billing_housenumber_add: authUserStore.customer.customers_housenumber_add,
    billing_city: authUserStore.customer.customers_city,
    billing_postcode: authUserStore.customer.customers_zipcode,
    billing_country: 'Nederland',
    billing_company: authUserStore.customer.customers_company_name,
    billing_telephone: authUserStore.customer.customers_telephone,
    ...mergeData
  };
}


export function cartStoreToPickUpData(cartStore: CartStore) {
  return Object.keys(cartStore.pickup_order_products).map((key) => {
    return {
      order_products_id: cartStore.pickup_order_products[key].orderProduct.orders_products_id,
      orders_products_date_pickup: cartStore.orders_date_delivery
    };
  });
}
