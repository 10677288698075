import {isLoggingIn, login, loginFailed, loginSucceeded, logOut, successFulRegistered} from '../actions/auth.actions';
import {AuthStore} from '../models/auth-store.model';
import {AppState} from '../../../services/app-state';
import {createSelector} from '@ngrx/store';

const initialAuth: AuthStore = {
  token: null,
  isLoggingIn: false
};

export function AuthReducer(state = initialAuth, action: any = {type: ''}) {
  switch (action.type) {
    case login.type:
      return {...state};

    case isLoggingIn.type:
      return {...state, isLoggingIn: true};

    case loginSucceeded.type:
    case successFulRegistered.type:
      return {...state, token: action.bearer, isLoggingIn: false};

    case loginFailed.type:
    case logOut.type:
      return {...initialAuth};

    default:
      return state;
  }
}

export const selectIsLoggingIn = (state: AppState) => state.auth.isLoggingIn;
export const selectToken = (state: AppState) => state.auth.token;

export const bearerToken = createSelector(
  selectToken,
  (state: string) => state
);

export const isLoggedIn = createSelector(
  selectIsLoggingIn,
  (state: boolean) => state
);
