import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AppState} from '../../../services/app-state';
import {Store} from '@ngrx/store';
import {NavController} from '@ionic/angular';
import {logOut} from '../../auth/actions/auth.actions';
import {Router} from '@angular/router';

export class HttpAuthIntercepter implements HttpInterceptor {
  constructor(private router: Router, private navCtrl: NavController, private store: Store<AppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      console.log(event);
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status > 400 && err.status < 404) {
          console.warn('unauth!');
          this.handleUnauthorized();
          // redirect to the login route
          // or show a modal
        }
      }
    }));
  }

  handleUnauthorized() {
    this.store.dispatch(logOut());

    const LOGIN_ROUTE = '/login';

    if (this.router.url !== LOGIN_ROUTE) {
      this.navCtrl.navigateRoot(LOGIN_ROUTE);
    }

  }
}
