import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Product} from '../models/product.model';
import {environment} from '../../../../environments/environment';
import {shareReplay} from 'rxjs/operators';
import {HttpClient} from '../../http/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private productListSub$: {
    [id: string]: Observable<Product[]>
  } = {};

  private productsSub$: {
    [productId: number]: Observable<Product>
  } = {};

  constructor(private http: HttpClient) { }

  index(params: any = {}): Observable<Product[]> {
    const id = JSON.stringify(params);
    console.log(id);
    if (!this.productListSub$[id]) {
      this.productListSub$[id] = this.http
        .get(`${environment.apiHost}products`, { params })
        .pipe(
          shareReplay(1)
        );
    }
    return this.productListSub$[id];
  }

  find(productId: number): Observable<Product> {
    if (!this.productsSub$[productId]) {
      this.productsSub$[productId] = this.http
        .get(`${environment.apiHost}products/${productId}`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.productsSub$[productId];
  }
}
