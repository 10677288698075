import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Product} from '../../models/product.model';
import {getProductOverviewImage} from '../../helpers/product.helper';
import {CategoriesService} from '../../services/categories.service';
import {take} from 'rxjs/operators';
import {ProductCategory} from '../../models/product-category.model';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  @Input() products: Product[];
  @Input() categoryId: number = null;
  @Output() productClicked: EventEmitter<Product> = new EventEmitter<Product>();
  public category: ProductCategory = null;

  constructor(private categoryService: CategoriesService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.categoryId) {
      this.categoryService.get(this.categoryId).pipe(take(1)).subscribe((res: ProductCategory) => {
        this.category = res;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  getProductCardStyle(product: Product) {
    return {
      'background-image': `url(${getProductOverviewImage(product)}`,
      'background-position': 'center center'
    };
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
