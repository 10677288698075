import {CartStore} from '../models/cart-store.model';
import {
  addPickupOrderProduct,
  addToCart,
  clearCart, clearProductMarkers,
  removePickupOrderProduct,
  setDeliveryAddressIsSet,
  setDeliveryDate,
  setFixedLocation,
  setMapInfo,
  setOrderDetails,
  setOrderNotice,
  setPreferredTimeslot,
  setProductAmount,
  unsetFixedLocation,
  unsetFixedLocationIfNoProducts
} from '../actions/cart.actions';
import {AppState} from '../../../services/app-state';
import {createSelector} from '@ngrx/store';
import {CartStoreProduct} from '../models/cart-store-product.model';
import {CartTotals} from '../models/cart-totals.model';
import {CartStorePickupProduct} from '../models/cart-store-pickup-product.model';
import {CartOrderDetails} from '../models/cart-order-details.model';
import {MapInfo} from '../models/map-info.model';
import {environment} from '../../../../environments/environment';
import { calculateMapInfo } from '../../maps/providers/google-maps.helper';

const initialState: CartStore = {
  address_by_order_id: null,
  order_products: {},
  pickup_order_products: {},
  delivery_address_is_set: false,
  preferred_timeslot: null,
  orders_date_delivery: null,
  orders_customer_notice: null,
  map_info: {
    delivery_latitude: null,
    delivery_longitude: null,
    product_markers: []
  },
  order_details: {
    customers_telephone: null,

    delivery_firstname: null,
    delivery_lastname: null,
    delivery_middlename: null,
    delivery_street_address: null,
    delivery_housenumber: null,
    delivery_housenumber_add: null,
    delivery_city: null,
    delivery_postcode: null,
    delivery_country: null,
    delivery_company: null,

    billing_firstname: null,
    billing_lastname: null,
    billing_middlename: null,
    billing_street_address: null,
    billing_housenumber: null,
    billing_housenumber_add: null,
    billing_city: null,
    billing_postcode: null,
    billing_country: null,
    billing_company: null,
  }
};

const defaultTax = 21;

export function CartReducer(state = initialState, action: any = {}) {
  state = {...state};

  switch (action.type) {
    case setProductAmount.type:
      state.order_products = {
        ...state.order_products,
        [`id-${action.product.products_id}`]: {
          product: action.product,
          amount: action.amount
        }
      };

      return state;

    case addPickupOrderProduct.type:
      // Arvin: for now, only 1 order can be active
      state.pickup_order_products = Object.keys(state.pickup_order_products)
        .map((productId) => state.pickup_order_products[productId]).concat({
          orderProduct: action.orderProduct
        }).reduce((res, orderProduct) => {
          if (orderProduct.orderProduct.orders_id === action.orderProduct.orders_id) {
            res[`id-${orderProduct.orderProduct.orders_products_id}`] = orderProduct;
          }
          return res;
        }, {});

      return state;

    case removePickupOrderProduct.type:
      state.pickup_order_products = {...state.pickup_order_products};
      delete state.pickup_order_products[`id-${action.orderProductId}`];

      return state;

    case setFixedLocation.type:
      state.address_by_order_id = action.orderId;

      return state;

    case setMapInfo.type:
      state.map_info = {...action.mapInfo};
      state.map_info.product_markers = action.mapInfo.product_markers.map((marker) => ({...marker}));

      return state;

    case unsetFixedLocation.type:
      state.address_by_order_id = null;
      return state;


    case clearProductMarkers.type:
      state.map_info = {
        ...state.map_info,
        product_markers: []
      };
      return state;

    case unsetFixedLocationIfNoProducts.type:
      if (Object.keys(state.pickup_order_products).length === 0 && Object.keys(state.order_products).length === 0) {
        state.address_by_order_id = null;
      }

      return state;

    case setPreferredTimeslot.type:
      state.preferred_timeslot = action.preferredTimeslot;
      return state;

    case clearCart.type:
      return {...initialState};

    case setDeliveryDate.type:
      state.orders_date_delivery = action.deliveryDate;
      return state;

    case setOrderDetails.type:
      state.order_details = {...action.orderDetails};
      if (typeof action.mapInfo !== 'undefined') {
        state.map_info = {...action.mapInfo};
        state.map_info.product_markers = action.mapInfo.product_markers.map((productMarker) => {
          return {...productMarker};
        });
      }
      return state;

    case setDeliveryAddressIsSet.type:
      state.delivery_address_is_set = action.deliveryAddressIsSet;
      return state;

    case setOrderNotice.type:
      state.orders_customer_notice = action.orders_customer_notice;
      return state;

    case addToCart.type:
      state.order_products = {...state.order_products};
      const newAmount = state.order_products[`id-${action.product.products_id}`] ?
        state.order_products[`id-${action.product.products_id}`].amount + action.amount : action.amount;

      if (newAmount === 0) {
        delete state.order_products[`id-${action.product.products_id}`];
      } else {
        state.order_products = {
          ...state.order_products,
          [`id-${action.product.products_id}`]: {
            product: action.product,
            amount: newAmount
          }
        };
      }

      return state;

    default:
      state = {...state};

      return state;
  }
}

export const cartPreferredTimeslot = (state: AppState) => state.cart.preferred_timeslot;
export const selectMapInfo = (state: AppState) => state.cart.map_info;
export const selectDeliveryAddressIsSet = (state: AppState) => state.cart.delivery_address_is_set;
export const cartDeliveryDate = (state: AppState) => state.cart.orders_date_delivery;
export const selectOrderDetails = (state: AppState) => state.cart.order_details;
export const selectAddressByOrderId = (state: AppState) => state.cart.address_by_order_id;

export const products = (state: AppState) => Object.keys(state.cart.order_products)
  .map((productId) => state.cart.order_products[productId]);

export const pickupOrderProducts = (state: AppState) => Object.keys(state.cart.pickup_order_products)
  .map((orderProductId) => state.cart.pickup_order_products[orderProductId]);

export const totalProductsAmount = createSelector(
  products,
  (cartStoreProducts: CartStoreProduct[]) => cartStoreProducts.reduce((total, cartStoreProduct) => {
    return cartStoreProduct.amount + total;
  }, 0)
);

export const productsInCart = createSelector(
  products,
  (cartStoreProducts: CartStoreProduct[]) => cartStoreProducts
);

export const pickupOrderProductsInCart = createSelector(
  pickupOrderProducts,
  (orderProducts: CartStorePickupProduct[]) => orderProducts
);
export const currentPickupOrderId = createSelector(
  pickupOrderProducts,
  (orderProducts: CartStorePickupProduct[]) => {
    if (orderProducts.length > 0) {
      return orderProducts[0].orderProduct.orders_id;
    }
    return null;
  }
);

export const cartTotals = createSelector(
  products,
  (cartStoreProducts: CartStoreProduct[]): CartTotals => {
    return cartStoreProducts.reduce(
      (totals, cartStoreProduct) => {

        const productSubTotal = cartStoreProduct.amount * cartStoreProduct.product.price;
        const productTaxTotal = cartStoreProduct.amount * cartStoreProduct.product.tax;
        totals.subTotal += productSubTotal;
        totals.totals += productSubTotal + productTaxTotal;

        let findTaxIndex = totals.tax.findIndex((tax) => tax.taxPercentage === defaultTax);
        if (findTaxIndex === -1) {
          totals.tax.push({
            taxPercentage: defaultTax,
            taxTotals: 0
          });
          findTaxIndex = totals.tax.length - 1;
        }

        totals.tax[findTaxIndex].taxTotals += productTaxTotal;

        return totals;
      }, {
        subTotal: 0,
        tax: [],
        totals: 0
      });
  }
);


export const preferredTimeslot = createSelector(
  cartPreferredTimeslot,
  (value: string) => value
);

export const orderDetails = createSelector(
  selectOrderDetails,
  (value: CartOrderDetails) => value
);

// Arvin: als je iets bestelt/wisselt voor een specifieke locatie, dan mag je het adres niet wijzigen
export const fixedLocation = createSelector(
  selectAddressByOrderId,
  (value: number) => !!value
);

export const deliveryDate = createSelector(
  cartDeliveryDate,
  (value: string) => value
);

export const deliveryAddressIsSet = createSelector(
  selectDeliveryAddressIsSet,
  (value: boolean) => value
);


export const googleMapsPreview = createSelector(
  selectMapInfo,
  (mapInfo: MapInfo) => {
    if (mapInfo.product_markers.length === 0) {
      return null;
    }
    const res = ['http://maps.googleapis.com/maps/api/staticmap?size=600x400&scale=2&key=' + environment.googleMapsKey];
    res.push(`maptype=satellite`);
    let prevProductId = null;
    let index = -1;
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const labels = [];
    mapInfo.product_markers.forEach((productMarker) => {
      if (productMarker.product.products_id !== prevProductId) {
        index = index + 1;
        const tmp = document.createElement('DIV');
        tmp.innerHTML = productMarker.product.title;
        prevProductId = productMarker.product.products_id;
        labels.push({
          indicator: alphabet[index],
          product: tmp.textContent || tmp.innerText || '',
          products_id: productMarker.product.products_id,
        });
      }
      res.push(`markers=size:mid|label:${alphabet[index]}|${productMarker.coords.lat},${productMarker.coords.long}`);
    });
    if (mapInfo.product_markers.length === 0) {
      res.push(`zoom=17`);
      res.push(`center=${mapInfo.delivery_latitude},${mapInfo.delivery_longitude}`);
    } else {
      const coords = [{
        lat: mapInfo.delivery_latitude,
        long: mapInfo.delivery_longitude
      }];
      mapInfo.product_markers.forEach((productMarker) => {
        coords.push(productMarker.coords);
      });
      const coordInfo = calculateMapInfo(coords);

      res.push(`zoom=${coordInfo.zoom}`);
      res.push(`center=${coordInfo.lat},${coordInfo.long}`);
    }
    return {
      labels,
      googleMapsString: `${res.join('&')}`
    };
  }
);
