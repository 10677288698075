/** Action to log in the User from the Login Page */
import {createAction, props} from '@ngrx/store';
import {AuthCredentials} from '../models/auth-credentials.model';
import {Auth} from '../models/auth.model';

export const login = createAction(
  '[Login Page] Login',
  props<AuthCredentials>(),
);

export const isLoggingIn = createAction(
  '[Login Page] Is logging in',
  () => ({ isLoggingIn: true }),
);

export const loginSucceeded = createAction(
  '[Login Page] Login succeeded',
  props<Auth>()
);

// Arvin: zelfde als loginSucceeded, maar komt na registratie
export const successFulRegistered = createAction(
  '[Login Page] SuccessFul registered',
  props<Auth>()
);


export const loginFailed = createAction(
  '[Login Page] Login failed'
);

export const logOut = createAction(
  '[Login Page] Logout'
);

/*
import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {AuthStore} from '../models/auth-store.model';
import {AuthCredentials} from '../models/auth-credentials.model';
@Injectable()
export class AuthActions {

  static LOGIN = 'LOGIN';

  static LOGIN_SUCCESS = 'LOGIN_SUCCESS';

  static LOGIN_FAILED = 'LOGIN_FAILED';

  static LOG_OUT = 'LOG_OUT';

  login(authCredentials: AuthCredentials) {
    return {
      type: AuthActions.LOGIN,
      payload: authCredentials
    };
  }

  loginSuccess(authStore: AuthStore) {
    return {
      type: AuthActions.LOGIN_SUCCESS,
      payload: authStore
    };
  }

  loginFailed(): Action {
    return {
      type: AuthActions.LOGIN_FAILED
    };
  }

  logOut(): Action {
    return {
      type: AuthActions.LOG_OUT
    };
  }
}
*/
