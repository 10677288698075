import {Component, OnInit} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {CategoriesService} from '../../../products/services/categories.service';
import {Observable} from 'rxjs';
import {ProductCategory} from '../../../products/models/product-category.model';
import {ModalController} from '@ionic/angular';
import {ProductService} from '../../../products/services/product.service';
import {Product} from '../../../products/models/product.model';

@Component({
  selector: 'app-product-selector-modal',
  templateUrl: './product-selector-modal.page.html',
  styleUrls: ['./product-selector-modal.page.scss'],
})
export class ProductSelectorModalPage implements OnInit {
  public categories$: Observable<ProductCategory[]>;
  public selectedProductCategory: ProductCategory;
  public products$: Observable<Product[]>;
  title: string;

  constructor(private categoriesService: CategoriesService, private productService: ProductService, private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.categories$ = this.categoriesService.index().pipe(
      map((categories) => categories.filter((category) => category.product_categories_inactive === 0)),
      take(1));
  }

  categoryClicked($event: ProductCategory) {
    this.selectedProductCategory = $event;

    const params = {
      product_categories_id: $event.product_categories_id
    };
    this.products$ = this.productService.index(params).pipe(take(1));
  }

  productClicked(product: Product) {
    this.modalCtrl.dismiss({product});
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
