import {Component} from '@angular/core';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppState} from './services/app-state';
import {select, Store} from '@ngrx/store';
import {logOut} from './modules/auth/actions/auth.actions';
import {selectIsLoggingIn} from './modules/auth/reducers/auth.reducer';
import {combineAll, filter, map, mergeMap, switchMap, take} from 'rxjs/operators';
import {AuthService} from './modules/auth/providers/auth-service';
import {setAuthUser} from './modules/auth/actions/auth-user.actions';
import {combineLatest, Observable, of} from 'rxjs';
import {orderDetails} from './modules/cart/reducers/cart.reducer';
import {AuthUser} from './modules/auth/models/auth-user.model';
import {CartOrderDetails} from './modules/cart/models/cart-order-details.model';
import {deliveryDataToBillingData} from './modules/cart/services/cart.helper';
import {setOrderDetails} from './modules/cart/actions/cart.actions';
import {BsLocaleService, defineLocale} from 'ngx-bootstrap';
import { nlLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private localeService: BsLocaleService,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    this.initializeApp();


    defineLocale('nl', nlLocale);
    localeService.use('nl');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.store.pipe(
        select(selectIsLoggingIn),
        take(1),
        filter((isLoggingIn) => isLoggingIn),
        switchMap(() => this.authService.getAuthUser()),
        switchMap((authUser): Observable<[CartOrderDetails, AuthUser]> =>
          this.store.pipe(select(orderDetails)).pipe(map((res) => [res, authUser]))),
        take(1),
      ).subscribe(([cartOrderDetails, authUser]) => {
        console.log(cartOrderDetails, authUser);
        this.store.dispatch(setAuthUser({authUser}));
        const generatedOrderDetails = {...cartOrderDetails, ...deliveryDataToBillingData(authUser.customer)};
        this.store.dispatch(setOrderDetails({ orderDetails: generatedOrderDetails}));
      });
    });
  }

  logout() {
    this.store.dispatch(logOut());
    this.navCtrl.navigateRoot('/login');
  }

  openInPopup(route: string) {
    this.navCtrl.navigateForward(route);
  }
}
