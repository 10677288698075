import {Product} from '../models/product.model';
import {OrderProduct} from '../../orders/models/order-product.model';

export function getProductDetailedImage(product: Product): string {
  if (product.images.length > 0) {
    return product.images[0];
  }
  return null;
}
export function getProductOverviewImage(product: Product): string {
  if (product.images.length > 0) {
    return product.images[product.images.length - 1];
  }
  return null;
}
