import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductCategory} from '../../../products/models/product-category.model';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  @Input() categories: ProductCategory[];
  @Output() categoryClicked: EventEmitter<ProductCategory> = new EventEmitter<ProductCategory>();
  constructor() { }

  ngOnInit() {}
}
