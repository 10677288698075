import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {from, Observable} from 'rxjs';
import {AuthStore} from '../models/auth-store.model';

import PouchDB from 'pouchdb';

@Injectable({
  providedIn: 'root'
})
export class AuthLocalDbService {
  private db;

  constructor(private platform: Platform) {
  }

  initDB(): Promise<any> {
    return this.platform.ready()
      .then(() => {
        this.db = new PouchDB('auth');
      });
  }

  setAuth(auth: AuthStore): Observable<any> {
    return from(
      this.initDB()
        .then(() => this.db.post(auth))
    );
  }

  clearAuth(): Observable<any> {
    return Observable.create((observable) => {
      this.initDB().then(() => {
        this.db.destroy().then(() => {
          this.initDB();
          observable.next();
          observable.complete();
        });
      });
    });
  }


  getAuth(): Observable<any> {
    return from(
      this.initDB()
        .then(() => {
          return this.db.allDocs({include_docs: true});
        })
        .then(docs => {
          return docs.rows.length > 0 ? docs.rows[0].doc : null;
        }));
  }

  getChanges(): Observable<any> {
    return Observable.create(observer => {

      // Listen for changes on the database.
      this.db.changes({live: true, since: 'now', include_docs: true})
        .on('change', change => {
          // Convert string to date, doesn't happen automatically.
          change.doc.date = new Date(change.doc.date);
          observer.next(change.doc);
        });
    });
  }
}
