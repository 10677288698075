import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {shareReplay} from 'rxjs/operators';
import {HttpClient} from '../../http/services/http-client.service';
import {HttpClient as Http} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private addressByCordsSub$: {
    [id: string]: Observable<any>
  } = {};
  private addressByZipcodeSub$: {
    [id: string]: Observable<any>
  } = {};
  private addressByGoogleMaps$: {
    [id: string]: Observable<any>
  } = {};

  constructor(private http: HttpClient, private orgHttp: Http) { }

  getAddressByCords(lat: number, long: number): Observable<FoundAddress> {
    const key = `${lat}-${long}`;
    if (!this.addressByCordsSub$[key]) {
      this.addressByCordsSub$[key] = this.http
        .get(`${environment.apiHost}address/lat-long/${lat}/${long}`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.addressByCordsSub$[key];
  }

  getAddressByZipcode(zipcode: string, housenumber: string|number): Observable<FoundAddress> {
    const key = `${zipcode}-${housenumber}`;
    if (!this.addressByZipcodeSub$[key]) {
      this.addressByZipcodeSub$[key] = this.http
        .get(`${environment.apiHost}address/autocomplete/${zipcode}/${housenumber}`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.addressByZipcodeSub$[key];
  }

  getAddressByGoogleMaps(place: string): Observable<{
    results: {
      geometry: {
        location: {
          lat: number,
          lng: number
        }
      }
    }[],
    status: 'string'
  }> {
    const key = `${place}`;
    if (!this.addressByGoogleMaps$[key]) {
      this.addressByGoogleMaps$[key] = this.orgHttp
        .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${place}&country=Netherlands&key=${environment.googleMapsKey}`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.addressByGoogleMaps$[key];
  }
}
