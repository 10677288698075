import {AuthUserStore} from '../models/auth-user-store.model';
import {clearAuthUser, setAuthUser, setAuthUserCustomer} from '../actions/auth-user.actions';
import {AppState} from '../../../services/app-state';
import {createSelector} from '@ngrx/store';

const initialAuthUser: AuthUserStore = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  customers_id: null,
  customer: null
};

export function AuthUserReducer(state = initialAuthUser, action: any = {type: ''}) {
  switch (action.type) {
    case clearAuthUser.type:
      return {...initialAuthUser};

    case setAuthUser.type:
      return {
        ...state,
        id: action.authUser.id,
        first_name: action.authUser.first_name,
        last_name: action.authUser.last_name,
        email: action.authUser.email,
        customers_id: action.authUser.customers_id,
        customer: {...action.authUser.customer}
      };

    case setAuthUserCustomer.type:
      return {
        ...state,
        customer: {...action.customer}
      };


    default:
      return state;
  }
}


export const selectAuthUser = (state: AppState): AuthUserStore => state.authUser;

export const authUser = createSelector(
  selectAuthUser,
  (selectedAuthUser: AuthUserStore) => selectedAuthUser
);
