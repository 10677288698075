import {createAction, props} from '@ngrx/store';
import {AuthUser} from '../models/auth-user.model';
import {Customer} from '../../customer/models/customer.model';

export const clearAuthUser = createAction(
  '[Auth User] clear auth user'
);

export const setAuthUser = createAction(
  '[Auth User] set auth user',
  props<{ authUser: AuthUser }>(),
);

export const setAuthUserCustomer = createAction(
  '[Auth User] set auth user customer',
  props<{ customer: Customer }>(),
);
