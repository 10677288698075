import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../services/app-state';
import {totalProductsAmount} from '../../../cart/reducers/cart.reducer';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-tabs-component',
  template: `
      <ion-tabs class="ion-tabs-custom">

          <ion-tab-bar slot="bottom">
              <ion-tab-button (click)="goTo('/tabs/home')"  [selected]="isActive('/tabs/home')">
                  <ion-icon name="home"></ion-icon>
                  <ion-label>Home</ion-label>
              </ion-tab-button>

              <ion-tab-button (click)="goTo('/tabs/my-orders')"  [selected]="isActive('/tabs/my-orders')">
                  <ion-icon src="assets/tab-container.svg"></ion-icon>
                  <ion-label>Mijn containers</ion-label>
              </ion-tab-button>

              <ion-tab-button (click)="goTo('/tabs/cart-overview')" [selected]="isActive('/tabs/cart-overview')">
                  <ion-icon name="cart"></ion-icon>
                  <ion-label>({{cartTotals$ | async}}) winkelwagen</ion-label>
              </ion-tab-button>
          </ion-tab-bar>

      </ion-tabs>
  `
})
export class TabsComponent implements OnInit {
  constructor(private store: Store<AppState>, private router: Router, private navCtrl: NavController) { }

  ngOnInit() {}

  get cartTotals$()  {
    return this.store.pipe(select(totalProductsAmount));
  }

  goTo(link: string) {
    this.navCtrl.navigateForward(link, {animationDirection: 'forward'});
  }

  isActive(instruction: string): boolean {
    return this.router.isActive(instruction, true);
  }
}
