import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../services/app-state';
import {Observable} from 'rxjs';
import {bearerToken} from '../../auth/reducers/auth.reducer';
import {HttpClient as Http, HttpHeaders} from '@angular/common/http';
import {map, mergeMap, take} from 'rxjs/operators';

@Injectable()
export class HttpClient {
  private headers;
  public baseApi = '';

  constructor(private store: Store<AppState>, private http: Http) {
  }

  private serialize(obj: any, prefix: any = null): string {
    const str: any = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
        str.push(typeof v === 'object' ?
          this.serialize(v, k) :
          // encodeURIComponent(k) + '=' + encodeURIComponent(v));
          k + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }

  private createHeader(token: string) {
    const header: any = {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json'
    };

    if (token && token.length > 0) {
      header.Authorization = 'Bearer ' + token;
    }

    return new HttpHeaders(header);
  }

  private convertedIdsToInts(obj: any = {}) {
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (p.substr(-3) === '_id' && !isNaN(obj[p])) {
          obj[p] = +obj[p];
        }
      }
    }
    return obj;
  }

  public get(url: string, params: any = {}): Observable<any> {
    return this.store.pipe(
      select(bearerToken),
      take(1),
      map((token) => this.createHeader(token)),
      mergeMap((headers) => {
        params.headers = headers;
        return this.http.get(url, params);
      }),
      take(1)
    );
  }

  public post(url: string, data: any = {}, params: any = {}): Observable<any> {
    return this.store.pipe(
      select(bearerToken),
      take(1),
      map((token) => this.createHeader(token)),
      mergeMap((headers) => {
        return this.http.post(url, data, {
          headers
        });
      }),
      take(1)
    );
  }

  public put(url: string, data: any = {}, params: any = {}): Observable<any> {
    data = this.convertedIdsToInts(data);
    return this.store.pipe(
      select(bearerToken),
      take(1),
      map((token) => this.createHeader(token)),
      mergeMap((headers) => {
        return this.http.put(url, data, {headers});
      }),
      take(1)
    );
  }

  public delete(url: string, params: any = {}): Observable<any> {
    return this.http.delete(url, {
      headers: this.headers
    });
  }
}

