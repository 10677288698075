import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../../services/app-state';
import {mergeMap, switchMap, tap} from 'rxjs/operators';
import {isLoggingIn, loginSucceeded, logOut, successFulRegistered} from '../actions/auth.actions';
import {AuthService} from '../providers/auth-service';
import {of} from 'rxjs';
import {NavController} from '@ionic/angular';
import {AuthLocalDbService} from '../providers/auth-local-db.service';
import {clearAuthUser, setAuthUser} from '../actions/auth-user.actions';
import {clearCart} from '../../cart/actions/cart.actions';

@Injectable()
export class AuthEffects {

  constructor(private store: Store<AppState>,
              private authService: AuthService,
              private authLocalDbService: AuthLocalDbService,
              private navCtrl: NavController,
              private readonly actions$: Actions) {
  }

  loginSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSucceeded.type),
        // tap((payload) => this.authLocalDbService.setAuth(payload).pipe(take(1))),
        switchMap(() => {
          return this.authService.getAuthUser().pipe(
            mergeMap((authUser) => [
              isLoggingIn(),
              setAuthUser({authUser})
            ])
          );
        }),
        tap(() => this.navCtrl.navigateRoot('/tabs/home'))
      );
    });

  successFulRegistered$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(successFulRegistered.type),
        // tap((payload) => this.authLocalDbService.setAuth(payload).pipe(take(1))),
        switchMap(() => {
          return this.authService.getAuthUser().pipe(
            mergeMap((authUser) => [
              isLoggingIn(),
              setAuthUser({authUser})
            ])
          );
        }),
        tap((data) => {
          console.log(data);
          this.navCtrl.navigateRoot('/register-my-account');
        })
      );
    });

  logOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logOut.type),
        // tap(() => this.authLocalDbService.clearAuth().pipe(take(1))),
        switchMap(() => [
          clearAuthUser(),
          clearCart()
        ])
      );
    });

  /*
  login$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(login.type),
        switchMap((payload) => {
            console.log(payload);
            return concat(
              of(isLoggingIn()),
              this.authService.login(payload).pipe(
                map(() => loginSucceeded()),
                catchError(() => of(loginFailed()))
              )
            );
          }
        ),
      );
  });
   */
}


