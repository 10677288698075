import {Injectable} from '@angular/core';
import {AuthCredentials} from '../models/auth-credentials.model';
import {Observable, of} from 'rxjs';
import {Auth} from '../models/auth.model';
import {environment} from '../../../../environments/environment';
import {convertFormData} from '../../http/services/http.helper';
import {AuthUser} from '../models/auth-user.model';
import {HttpClient} from '../../http/services/http-client.service';
import {RegisterForm} from '../models/register-form.model';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient) {}


  public login(authCredentials: AuthCredentials): Observable<Auth> {
    return this.http.post(`${environment.apiHost}login`, convertFormData(authCredentials));
  }
  public register(registerForm: RegisterForm): Observable<Auth> {
    return this.http.post(`${environment.apiHost}users`, convertFormData(registerForm));
  }

  public getAuthUser(): Observable<AuthUser> {
    return this.http.get(`${environment.apiHost}auth/user`);
  }

  /*
public getAuthUser(): Observable<any> {
  return this.http.get(this.http.baseApi + 'authenticate/user').map(res => {
    res = res.json();
    return res.user;
  });

}
   */

  logOut() {
    /*
    this.unQueueExpiringTokenValidation();
    this.store.dispatch(this.authUserActions.clearUser());
    this.store.dispatch(this.authActions.logOut());
    this.app.getRootNav().setRoot(LoginPage);
     */
  }
}
