import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ProductSelectorModalPage} from './product-selector-modal.page';
import {CategoriesModule} from '../../categories.module';
import {ProductsModule} from '../../../products/products.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CategoriesModule,
    ProductsModule,
  ],
  exports: [ProductSelectorModalPage],
  declarations: [ProductSelectorModalPage],
  entryComponents: [ProductSelectorModalPage]
})
export class ProductSelectorModalPageModule {
}
