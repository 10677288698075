/** Action to log in the User from the Login Page */
import {createAction, props} from '@ngrx/store';
import {Product} from '../../products/models/product.model';
import {OrderProduct} from '../../orders/models/order-product.model';
import {CartOrderDetails} from '../models/cart-order-details.model';
import {MapInfo} from '../models/map-info.model';

export const setProductAmount = createAction(
  '[Cart] set product amount',
  props<{ product: Product, amount: number }>(),
);

export const addToCart = createAction(
  '[Cart] add to cart',
  props<{ product: Product, amount: number }>(),
);

export const addPickupOrderProduct = createAction(
  '[Cart] add product to pick up',
  props<{ orderProduct: OrderProduct }>(),
);

export const removePickupOrderProduct = createAction(
  '[Cart] remove order product from pickup',
  props<{ orderProductId: number }>(),
);

export const setPreferredTimeslot = createAction(
  '[Cart] set preferred timeslot',
  props<{ preferredTimeslot: string }>(),
);
export const setDeliveryDate = createAction(
  '[Cart] set delivery date',
  props<{ deliveryDate: string }>(),
);
export const setOrderNotice = createAction(
  '[Cart] set order notice',
  props<{ orders_customer_notice: string }>(),
);

export const setOrderDetails = createAction(
  '[Cart] set order details',
  props<{ orderDetails: CartOrderDetails, mapInfo?: MapInfo }>(),
);

export const setMapInfo = createAction(
  '[Cart] set map info',
  props<{ mapInfo: MapInfo }>(),
);

export const clearProductMarkers = createAction(
  '[Cart] clear product markers',
);

export const clearCart = createAction(
  '[Cart] clear cart'
);

export const setFixedLocation = createAction(
  '[Cart] set fixed location',
  props<{ orderId: number }>(),
);

export const setDeliveryAddressIsSet = createAction(
  '[Cart] set delivery address is set',
  props<{ deliveryAddressIsSet: boolean }>(),
);

export const unsetFixedLocation = createAction(
  '[Cart] unset fixed location'
);

export const unsetFixedLocationIfNoProducts = createAction(
  '[Cart] unset fixed location if there are no (pickup)products anymore'
);
